import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IVacancyReasonEntity } from '../../interfaces/api/vacancy-reason-entity';

export function sortByName(a: IVacancyReasonEntity, b: IVacancyReasonEntity): number {
  return a.display.localeCompare(b.display);
}

export const adapter: EntityAdapter<IVacancyReasonEntity> =
  createEntityAdapter<IVacancyReasonEntity>({
    sortComparer: sortByName,
    selectId: (vacancyReason: IVacancyReasonEntity) => vacancyReason.val,
  });

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedVacancyReasonId: null,
});
