import { IHospitalVacancyReasonEntity } from '../../interfaces/api/hospital-vacancy-reason-entity';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';
import { MessageableFactory } from '@locumsnest/core/src/lib/ngrx';

export const messageableFactory =
  MessageableFactory.forFeature<'HospitalVacancyReason'>('HospitalVacancyReason');
export const loadingAdapter =
  createLoadingStateAdapter<'HospitalVacancyReason'>(messageableFactory);

export const adapter: EntityAdapter<IHospitalVacancyReasonEntity> =
  createEntityAdapter<IHospitalVacancyReasonEntity>({});

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedHospitalVacancyReasonId: null,
});
